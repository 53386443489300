import { Fragment, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import Card from '@speedwaymotors/clutch/Atoms/Card'
import ClassNames from 'classnames'

import getFitmentDetailsFromSelectedFacets from 'Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'

import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'

import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'
import useRecentHistory from 'Clutch/Hooks/useRecentHistory'

import EnhancedProductCard from 'Clutch/Organisms/EnhancedProductCard'

import Skeleton from 'Clutch/Atoms/Skeleton'

import {
  getLastPageVisited,
  setLastPageVisited,
} from '../../../Utilities/LocalStorage/previousPage'
import DiversificationRules from '../../../components/diversificationRules/diversificationRules'
import ExcludedProducts from '../../../components/excludedProducts/excludedProducts'
import FacetRules from '../../../components/facetRules/facetRules'
import IsFollowedAndIndexed from '../../../components/isFollowedAndIndexed/isFollowedAndIndexed'
import PhrasedQuery from '../../../components/phrasedQuery/phrasedQuery'
import ProductBoostingRules from '../../../components/productBoostingRules/productBoostingRules'
import ProductMatchScores from '../../../components/productMatchScores/productMatchScores'
import ProductOrdering from '../../../components/productOrdering/productOrdering'
import ProductRecalls from '../../../components/productRecalls/productRecalls'
import UserExperience from '../../../components/userExperience/userExperience'
import useProductResultPage from '../../../hooks/useProductResultPage'
import { useTimeToFirstProduct } from '../../../hooks/useTimeToFirstProduct'
import useVehicleSidebar from '../../../hooks/useVehicleSidebar'
import ArticleSearchCampaign from '../../campaign/ArticleSearchCampaign'
import Campaign from '../../campaign/campaign'
import styles from './horizontalPage.module.scss'
import { getNumProductsToBeLoaded } from '/Utilities/loadMoreUtilities'
import { usePrpStore } from '/contexts/prpContext'

const HorizontalPageLayout = ({}) => {
  const skeleton = useProductResultPage((x) => x.skeleton)
  const isSearchPage = useProductResultPage((x) => x.isSearchPage)
  const searchProducts = useProductResultPage((x) => x.searchProducts)
  const instrumentation = useProductResultPage((x) => x.instrumentation)
  const facetRuleTargets = useProductResultPage((x) => x.facetRuleTargets)
  const facetRules = useProductResultPage((x) => x.facetRules)
  const appliedPhrases = useProductResultPage((x) => x.appliedPhrases)
  const sminetAddNewFacetRuleUrl = useProductResultPage(
    (x) => x.sminetAddNewFacetRuleUrl,
  )
  const productRecalls = useProductResultPage((x) => x.productRecalls)
  const sminetAddProductRecallUrl = useProductResultPage(
    (x) => x.sminetAddProductRecallUrl,
  )
  const misoOrderByConfig = useProductResultPage((x) => x.misoOrderByConfig)
  const filteringRule = useProductResultPage((x) => x.filteringRule)
  const attribution = useProductResultPage((x) => x.attribution)
  const campaign = useProductResultPage((x) => x.campaign)
  const misoId = useProductResultPage((x) => x.misoId)
  const isMobile = usePrpStore((x) => x.isMobile)
  const pageContent = useProductResultPage((x) => x.pageContent)
  const navigationContext = useContext(NavigationContext)
  const addToListEnabled = useFeatureFlag('PRP_Frontend_Add_To_List')
  const addToListExpanded = useFeatureFlag('Add_To_List_Badge_Expanded')
  const addToCartEnabled = useFeatureFlag('PRP_Add_To_Cart')
  const compareToolEnabled = useFeatureFlag('PRP_Compare_Tool')
  const SavePrpHistoryWithSetTimeout = usePrpStore(
    (x) => x.SavePrpHistoryWithSetTimeout,
  )
  const LoadLazyProducts = usePrpStore((x) => x.LoadLazyProducts)
  const spaLoading = usePrpStore((x) => x.uiState.spaLoading)
  const numProductsLoading = usePrpStore((x) => x.uiState.numProductsLoading)
  const spaLoadId = usePrpStore((x) => x.state.spaLoadId)
  const OpenCompareToolModal = usePrpStore((x) => x.OpenCompareToolModal)
  const searchResponseProducts = searchProducts
  const loadingMoreProducts = usePrpStore((x) => x.uiState.loadingMoreProducts)
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults)
  const engineFitmentPageTitle = useProductResultPage(
    (x) => x.engineFitmentPageTitle,
  )
  const currentPage = useProductResultPage((x) => x.currentPage)
  const numProductsToLoad = getNumProductsToBeLoaded(
    totalSearchResults,
    currentPage,
  )
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const fitmentDetails =
    getFitmentDetailsFromSelectedFacets(selectedFacetOptions) || {}
  const { vehicleName } = useVehicleSidebar(
    selectedFacetOptions,
    fitmentDetails,
    engineFitmentPageTitle,
    true,
  )
  const isFollow = useProductResultPage((x) => x.isFollow)
  const isIndex = useProductResultPage((x) => x.isIndex)
  const fitmentSectionInteractive = useFeatureFlag(
    'PRP_FitmentSectionInteractivity',
  )

  if (typeof window !== 'undefined') {
    window.isYmmPage = attribution?.is_ymm
  }

  let isCampaignPresent = false
  let campaignComponentIndices = []

  if (campaign && campaign.campaignPositions) {
    isCampaignPresent = true
    campaignComponentIndices = campaign.campaignPositions?.campaignPositions
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '500px 0px',
  })

  useEffect(() => {
    if (typeof window !== 'undefined' && !skeleton) {
      try {
        const prpLastPageVisited = getLastPageVisited()
        if (
          prpLastPageVisited?.url == window.location.href &&
          prpLastPageVisited?.skuNumber
        ) {
          document
            .getElementById(
              `Horizontal_Product_Card_${prpLastPageVisited?.skuNumber}`,
            )
            .scrollIntoView({ block: 'center' })
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [skeleton])

  useEffect(() => {
    inView && LoadLazyProducts()
  }, [inView, spaLoadId])

  useTimeToFirstProduct()

  let hasHitFirstLazyLoadedProduct = false
  const LazyLoadProductsIntersectionObserver = () => {
    if (hasHitFirstLazyLoadedProduct || skeleton || spaLoading) {
      return false
    }
    hasHitFirstLazyLoadedProduct = true
    return <span ref={ref}></span>
  }

  const numSkelsToShow =
    numProductsLoading > 0 &&
    searchResponseProducts?.length > numProductsLoading
      ? numProductsLoading
      : 0
  const shouldRibbonUseDollarsOff = useFeatureFlag(
    'Ribbon_Text_Dollar_Vs_Percentage',
  )
  const hasAnyBoostingRulesOrScores = searchProducts?.some(
    (x) => x.boostingRules?.length || x.scores?.length,
  )
  const defaultDiversification =
    instrumentation?.metrics?.searchProviderRequest?.diversification

  return (
    <>
      {hasAnyBoostingRulesOrScores ? (
        <Card noMargin noPadding className={styles.card}>
          <div className={styles.debugCard}>
            <DiversificationRules
              defaultDiversification={defaultDiversification}
              facetRuleTargets={facetRuleTargets}
              facetRules={facetRules}
            />
            <PhrasedQuery appliedPhrases={appliedPhrases} />
            <FacetRules
              facetRules={facetRules}
              sminetAddNewFacetRuleUrl={sminetAddNewFacetRuleUrl}
            />
            <ProductRecalls
              productRecalls={productRecalls}
              sminetAddProductRecallUrl={sminetAddProductRecallUrl}
            />
            <ProductOrdering misoOrderByConfig={misoOrderByConfig} />
            <ExcludedProducts filteringRule={filteringRule} />
            <IsFollowedAndIndexed
              isFollow={isFollow}
              isIndex={isIndex}
              facetRules={facetRules}
              sminetAddNewFacetRuleUrl={sminetAddNewFacetRuleUrl}
            />
            <UserExperience />
          </div>
        </Card>
      ) : null}

      {(searchResponseProducts || []).map((product, i) => {
        if (product.lazyLoad || spaLoading) {
          if (numSkelsToShow > 0 && i >= numSkelsToShow) {
            return false
          }
          return (
            <div
              key={`horizontal-skeleton-${i}`}
              className={ClassNames(
                styles.productCard,
                styles.productCardSkeleton,
              )}
            >
              {LazyLoadProductsIntersectionObserver()}
              <Skeleton height={240} fullWidth />
            </div>
          )
        }

        let campaignComponent = null
        let lastCampaignIndex =
          campaignComponentIndices[campaignComponentIndices.length - 1]
        if (
          isCampaignPresent &&
          searchResponseProducts.length <= lastCampaignIndex
        )
          lastCampaignIndex = searchResponseProducts.length - 1

        if (
          isCampaignPresent &&
          i === 0 &&
          campaignComponentIndices.indexOf(0) > 0
        )
          campaignComponent = <Campaign placement={'embedded'} />

        if (isCampaignPresent && campaignComponentIndices.find((x) => x === i))
          campaignComponent = <Campaign placement={'embedded'} />

        if (!isCampaignPresent && !isMobile && i === 4) {
          campaignComponent = <ArticleSearchCampaign />
        }

        const productDetails = product.skuBaseDetails
        if (
          !campaignComponentIndices.find((x) => x === i) &&
          !productDetails &&
          !isCampaignPresent
        )
          return false

        const productCardClickEvent = (e) => {
          SavePrpHistoryWithSetTimeout()
        }

        const pdpProduct = {
          displayTitle: productDetails?.displayTitle,
          brand: productDetails?.brand,
          sku: productDetails?.skuBaseNumber,
          //image: productDetails?.imageLink ? (productDetails?.imageLink.includes("http") ? productDetails?.imageLink : `https:${productDetails?.imageLink}`) : null
        }

        if (!productDetails) {
          return false
        }

        function shouldShowDebugInfo() {
          return (
            hasAnyBoostingRulesOrScores &&
            (defaultDiversification ||
              facetRuleTargets?.some((x) => x?.diversification !== null) ||
              facetRules?.some((x) => x?.type === 'Diversification'))
          )
        }

        return (
          <Fragment
            key={`horizontal_product_outer_${i}_${productDetails.skuBaseNumber}`}
          >
            <article
              className={ClassNames(styles.productCard, 'productCard')}
              onClick={(e) => productCardClickEvent(e)}
              id={`Horizontal_Product_Card_${productDetails?.skuBaseNumber}`}
            >
              <div style={{ display: 'flex' }}>
                <EnhancedProductCard.Horizontal
                  product={productDetails}
                  showDebugInfo={shouldShowDebugInfo()}
                  fitmentTargetLabel={vehicleName}
                  page={'prp'}
                  search={isSearchPage}
                  shop={!isSearchPage}
                  provider={instrumentation.metrics.searchProviderName}
                  misoId={misoId}
                  imageLoadingProps={{
                    lazyBoundary: '500px',
                    priority: i < 2 && !pageContent?.isMakerPage,
                  }}
                  addToListEnabled={addToListEnabled}
                  addToListExpanded={addToListExpanded}
                  addToCartEnabled={addToCartEnabled}
                  compareToolEnabled={compareToolEnabled}
                  openCompareTool={() =>
                    OpenCompareToolModal(product.skuBaseNumber)
                  }
                  index={i}
                  shouldRibbonUseDollarsOff={shouldRibbonUseDollarsOff}
                  onClick={(e) => {
                    productCardClickEvent(e)

                    if (e.shiftKey || e.ctrlKey || e.metaKey) {
                      return
                    }

                    try {
                      if (misoId) {
                        useRecentHistory.SetRecentMisoId(
                          misoId,
                          productDetails.skuBaseNumber,
                        )
                      }

                      setLastPageVisited(productDetails?.skuBaseNumber)
                    } catch (err) {
                      console.error(err)
                    }

                    requestAnimationFrame(() => {
                      setTimeout(() =>
                        navigationContext.setPdpSkeleton(true, pdpProduct),
                      )
                    })
                  }}
                  fitmentSectionInteractive={fitmentSectionInteractive}
                  selectedFacetOptions={selectedFacetOptions}
                />
                {hasAnyBoostingRulesOrScores ? (
                  <>
                    <ProductBoostingRules
                      boostingRules={product.boostingRules}
                      productRecalls={productRecalls}
                    />
                    <ProductMatchScores
                      scores={product.scores}
                      matchedFields={product.matchedFields}
                    />
                  </>
                ) : null}
              </div>
              {productDetails?.shipping?.shippingEstimateDescription}
            </article>

            {campaignComponent}
          </Fragment>
        )
      })}

      {loadingMoreProducts
        ? new Array(numProductsToLoad).fill({}).map((fakeProduct, index) => {
                    return (
                      <div
                        key={`loading-skeleton-${index}`}
                        className={ClassNames(
                          styles.productCard,
                          styles.productCardSkeleton,
                        )}
                      >
                        <Skeleton height={240} fullWidth />
                      </div>
                    )
                  })
        : false}
    </>
  )
}

export default HorizontalPageLayout
