import React, { useCallback, useEffect, useState } from 'react'

import Area from '@speedwaymotors/clutch/Molecules/Area'
import ClassNames from 'classnames'

import Accordion from 'Clutch/Molecules/Accordion'

import ColorSwatch from 'Clutch/Atoms/ColorSwatch/ColorSwatch'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Skeleton from 'Clutch/Atoms/Skeleton'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import AppName from '../../../appName/appName'
import { usePrpStore } from '../../../contexts/prpContext'
import useProductResultPage from '../../../hooks/useProductResultPage'
import accordionStyles from '../shared/facetAccordion.module.scss'
import styles from './facet.module.scss'
import FacetOption from './facetOption/facetOption'
import FacetSayt from './facetSearchBar/facetSearchBar'

const Facet = ({
  facet,
  index,
  onFacetOptionClick,
  displayedInSidebarDrawer,
  debugModeEnabled,
}) => {
  const [facetOptions, setFacetOptions] = useState(
    facet?.searchResponseFacetOptions,
  )
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [optionsExpanded, setOptionsExpanded] = useState(false)
  const [isLoadMoreWaiting, setIsLoadMoreWaiting] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const misoId = useProductResultPage((x) => x.misoId)

  const lazyLoadedFacetsHydrated = useProductResultPage(
    (x) => x.lazyLoadedFacetsHydrated,
  )
  const initialSideBarFacetCount = useProductResultPage(
    (x) => x.initialSideBarFacetCount,
  )
  const seeMoreHydrated = useProductResultPage((x) => x.seeMoreHydrated)
  const spaLoading = usePrpStore((x) => x.uiState.spaLoading)
  const GetAllFacetLinks = usePrpStore((x) => x.GetAllFacetLinks)

  const sortBy = optionsExpanded ? 'orderedSortIndex' : 'relevanceSortIndex'

  useEffect(() => {
    if (seeMoreHydrated)
      setFacetOptions(
        typeof structuredClone !== 'undefined'
          ? structuredClone(
              facet?.searchResponseFacetOptions?.sort(
                (a, b) => a[sortBy] - b[sortBy],
              ) || [],
            )
          : JSON.parse(
              JSON.stringify(
                facet?.searchResponseFacetOptions?.sort(
                  (a, b) => a[sortBy] - b[sortBy],
                ) || [],
              ),
            ),
      )
  }, [seeMoreHydrated])

  useEffect(() => {
    if (lazyLoadedFacetsHydrated)
      setFacetOptions(
        typeof structuredClone !== 'undefined'
          ? structuredClone(
              facet?.searchResponseFacetOptions?.sort(
                (a, b) => a[sortBy] - b[sortBy],
              ) || [],
            )
          : JSON.parse(
              JSON.stringify(
                facet?.searchResponseFacetOptions?.sort(
                  (a, b) => a[sortBy] - b[sortBy],
                ) || [],
              ),
            ),
      )
  }, [lazyLoadedFacetsHydrated])

  const handleSeeMore = useCallback(() => {
    if (!seeMoreHydrated) {
      GetAllFacetLinks(setIsLoadMoreWaiting, () =>
        setOptionsExpanded(!optionsExpanded),
      )
    } else {
      setOptionsExpanded(!optionsExpanded)
    }
  }, [seeMoreHydrated, optionsExpanded])

  useEffect(() => {
    if (!spaLoading && (optionsExpanded || isWaiting)) {
      setIsWaiting(false)
      setOptionsExpanded(false)
    }
  }, [spaLoading, misoId])

  useEffect(() => {
    if (facet?.searchResponseFacetOptions?.length) {
      setFacetOptions(facet.searchResponseFacetOptions)
    }
  }, [facet?.searchResponseFacetOptions])

  const comparator = (a, b) => {
    if (optionsExpanded || !facet.isMultiSelect) return a[sortBy] - b[sortBy]
    if (a.isSelected && !b.isSelected) {
      return -1
    } else if (b.isSelected && !a.isSelected) {
      return 1
    } else {
      return a[sortBy] - b[sortBy]
    }
  }

  // if isSelected = true
  const summary = (
    <div className={styles.facetContainer}>
      <Typography
        className={styles.facetName}
        as={'h2'}
        font={'bold'}
        tone={'normal'}
        size={1}
        data-testid={`facetName_${facet.facetName}`}
      >
        {facet.facetName}
      </Typography>
      {facet?.isSelected && (
        <ColorSwatch brand={'secondarypalette_purple'} size={'M'} />
      )}
      {debugModeEnabled && facet.displayPosition !== null && (
        <Area className={styles.pinned} intent={'positive'}>
          <Typography size={0.75}>Pinned</Typography>
        </Area>
      )}
    </div>
  )

  if (facet.lazyLoaded) {
    if (facet.searchResponseFacetOptions?.length && index <= 4) {
      return (
        <>
          <OpenFacetSkeleton summary={summary} facet={facet} />
        </>
      )
    }
    return (
      <>
        <Skeleton height={20} fullWidth />
      </>
    )
  }

  const numInitialOptions = initialSideBarFacetCount ?? 6
  const numShowNow = optionsExpanded
    ? facet?.searchResponseFacetOptions?.length
    : numInitialOptions

  const defaultOpen =
    index <= 4 || !!facet?.searchResponseFacetOptions.find((x) => x.isSelected)

  return (
    <CoveringSpinner isLoading={isWaiting} className={styles.coverSpin}>
      <Accordion
        summary={summary}
        defaultOpen={defaultOpen}
        fill
        iconOpened={'chevron'}
        iconClosed={'chevron'}
        segmentAccordionName={`${AppName} Facet Accordion`}
        size={1}
        data-testid={'facet_' + facet.facetName}
        className={ClassNames(
          accordionStyles[
            `accordionSvgOpen_${defaultOpen ? !accordionOpen : accordionOpen}`
          ],
          `defaultOpen-${defaultOpen}`,
        )}
        onChange={() => setAccordionOpen(!accordionOpen)}
      >
        <FacetSayt facet={facet} setIsWaiting={setIsWaiting}>
          {facet.facetName === '' ? (
            <p>Placeholder for rating stars</p>
          ) : (
            <div className={styles.sideBarFacetOptions}>
              {(facetOptions?.sort(comparator) || []).map((option, i) => {
                return (facet.facetName === 'Discounts' && option.id === 3) ||
                  (facet.facetName === 'Availability' &&
                    option.id === -1) ? null : (
                  <FacetOption
                    doNotRenderIfHidden={displayedInSidebarDrawer}
                    facetName={facet.facetName}
                    setIsLoading={setIsWaiting}
                    isMultiSelect={facet.isMultiSelect}
                    onClick={onFacetOptionClick}
                    hidden={i >= numShowNow}
                    key={`facet_option_${facet.facetName}_${option.id}_${i}`}
                    facetOption={option}
                  />
                )
              })}
            </div>
          )}
          {facet.hasShowMore ? (
            <CoveringSpinner isLoading={isLoadMoreWaiting}>
              <TextLink
                role={'button'}
                aria-pressed={optionsExpanded}
                onClick={handleSeeMore}
                intent={'action'}
                size={1}
                font={'bold'}
                className={styles.show_more}
                data-testid={`${facet.facetName}_Show_More`}
                segmentEvent={{
                  event: 'Clicked',
                  properties: {
                    element: `${AppName} ${!optionsExpanded ? 'Show More' : 'Show Less'}`,
                  },
                }}
              >
                {!optionsExpanded ? 'Show More' : 'Show Less'}
              </TextLink>
            </CoveringSpinner>
          ) : null}
        </FacetSayt>
      </Accordion>
    </CoveringSpinner>
  )
}

const OpenFacetSkeleton = ({ summary, facet }) => {
  return (
    <>
      {summary}
      {facet?.hasShowMore && <Skeleton height={52} fullWidth />}
      {facet.searchResponseFacetOptions?.map((option, i) => {
        return (
          <Skeleton
            key={`facet_option_skeleton_${option.id}-${i}`}
            height={24}
            fullWidth
            className={styles.facetOptionSkeleton}
          />
        )
      })}
      {facet?.hasShowMore && (
        <Skeleton
          height={24}
          width={82}
          className={styles.facetOptionSkeleton}
        />
      )}
    </>
  )
}

export default Facet
